//colors vars
$btn-success: #2CA29B;
$btn-success-hover: #32b1aa;

$backdrop-color: rgba(146, 213, 251, 0.18) !important;

@media (min-width: 576px) {
  div.modal-dialog.modal-xl {
    min-width: 1200px !important;
  }
}

//.block-ui-wrapper {
//  background: $backdrop-color;
//  border-radius: 5px!important;
//  //backdrop-filter: blur(1px)!important;
//}
//div.block-ui-wrapper .loader {
//  border-color: transparent; /* Circle color */
//  border-left-color: #0095E8 ; /* Spinning section in circle color */
//}

@font-face {
  font-family: RobotoSlab;
  font-style: normal;
  src: url('/assets/fonts/Roboto_Slab/static/RobotoSlab-Bold.ttf');
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: RobotoSlab;
  font-style: normal;
  src: url('/assets/fonts/Roboto_Slab/static/RobotoSlab-Bold.ttf');
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: RobotoSlab;
  font-style: normal;
  src: url('/assets/fonts/Roboto_Slab/static/RobotoSlab-Regular.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Helvetica;
  font-style: normal;
  src: url('/assets/fonts/helvetica/Helvetica.ttf');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: Helvetica;
  font-style: normal;
  src: url('/assets/fonts/helvetica/Helvetica-Bold.ttf');
  font-display: swap;
  font-weight: 700;
}

:root {
  --color-main-blue: #384792;
  --color-secondary-blue: #009DE0;
  --color-main-green-transparent: rgba(13, 66, 62, 0.5);
  --color-text-dark: #0B132E;
  --color-text-gray: #858996;
  --color-text-header: #A0DF87;
  --color-text-red: #C63948;

}

.btn.active-tap {
  color: #181C32 !important;
  background-color: #E5F5FC !important;
  border: 1.5px solid #9BA3C9 !important;
}

.btn-active-paginator {
  color: white !important;
  background-color: #384792 !important;
}

.btn-light-designed {
  color: #009DE0 !important;
  border: 1.5px solid #009DE0 !important;
}

.btn-light-designed:hover {
  background-color: #009DE0 !important;
  color: white !important;
}

.btn-light-danger-designed {
  color: #E2303E !important;
  border: 1.5px solid #E2303E !important;
}

.btn-light-danger-designed:hover {
  background-color: #E2303E !important;
  color: white !important;
}

//Changing button styles for SweetAlert
button.swal2-cancel.btn.text-white.rounded.swal2-styled {
  color: #E2303E !important;
  border: 1.5px solid #E2303E !important;
  background-color: white !important;
}

button.swal2-cancel.btn.text-white.rounded.swal2-styled:hover {
  background-color: #E2303E !important;
  color: white !important;
}

button.swal2-confirm.btn.btn-primary.rounded.swal2-styled.swal2-default-outline {
  color: white !important;
  background-color: #384792 !important;
}

//Changing button styles for SweetAlert

.my-text-dark {
  color: var(--color-text-dark);
}

.my-text-gray {
  color: var(--color-text-gray);
}

.my-text-header {
  color: var(--color-main-blue);
}

.my-text-header-transparent {
  color: var(--color-main-green-transparent);
}

.my-text-header-secondary {
  color: var(--color-text-header);
}

.my-text-red {
  color: var(--color-text-red);
}

.my-text-yellow {
  color: #F9FABD;
}

.H0-70-600 {
  font-family: Helvetica, serif;
  //font-size: clamp(4.38rem, calc(3.99rem + 0.42vw), 5.00rem);
  font-size: 53px !important;
  font-weight: 600;
}


.H1-36-400 {
  font-family: Helvetica, serif;
  font-size: clamp(2.25rem, calc(1.87rem + 0.42vw), 2.88rem);
  font-weight: 400;
}

.H1-24-400 {
  font-family: Helvetica, serif;
  font-size: 24px !important;
  font-weight: 400;
}

.H1-36-700 {
  font-family: Helvetica, serif;
  font-size: clamp(2.25rem, calc(1.87rem + 0.42vw), 2.88rem);
  font-weight: 700;
}

.H1-30-700-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: clamp(1.88rem, calc(1.49rem + 0.42vw), 2.50rem);
  font-weight: 700;
}

.H2-22-700 {
  font-family: Helvetica, serif;
  font-size: clamp(1.38rem, calc(1.14rem + 1.11vw), 2.00rem);
  font-weight: 700;
}

.H2-20-700-Helvetica {
  font-family: Helvetica, serif !important;
  //font-size: clamp(1.25rem, calc(1.01rem + 1.11vw), 1.88rem);
  font-size: 20px !important;
  font-weight: 700;
}

.H2-22-400-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: clamp(1.38rem, calc(1.14rem + 1.11vw), 2.00rem);
  font-weight: 400;
}

.H3-18-700 {
  font-family: Helvetica, serif;
  font-size: clamp(1.13rem, calc(0.74rem + 0.42vw), 1.75rem);
  font-weight: 700;
}

.H3-18-700-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: clamp(1.13rem, calc(0.74rem + 0.42vw), 1.75rem);
  font-weight: 700;
}

.H3-18-400-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: clamp(1.13rem, calc(0.74rem + 0.42vw), 1.75rem);
  font-weight: 400;
}

.H4-16-700-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: clamp(1.00rem, calc(0.62rem + 0.42vw), 1.63rem);
  font-weight: 700;
}

.H4-16-700-RobotoSlab {
  font-family: Helvetica, serif;
  font-size: clamp(1.00rem, calc(0.62rem + 0.42vw), 1.63rem);
  font-weight: 700;
}

.H4-16-400-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: clamp(1.00rem, calc(0.62rem + 0.42vw), 1.63rem);
  font-weight: 400;
}

.H6-14-400-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: clamp(0.88rem, calc(0.64rem + 1.11vw), 1.50rem);
  font-weight: 400;
}

.H6-14-700-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: 16px !important;
  font-weight: 700;
}

.H8-12-400-Helvetica {
  font-family: Helvetica, serif !important;
  font-size: clamp(0.75rem, calc(0.51rem + 1.11vw), 1.38rem);
  font-weight: 500;
}

.my-input {
  padding-right: 5px;
  margin: 0 !important;
  width: 100% !important;
  outline: none;
  height: 64px;
  background: #FFFFFF !important;
  border: 1px solid #F1F5FF;
  border-radius: 8px;
}

.my-input-modal {
  padding-left: 10px;
  padding-right: 10px;
  color: #0D423E !important;
  margin: 0 !important;
  width: 100% !important;
  height: 56px !important;
  background: #F3F6F5;
  outline: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #0D423E;
  border-radius: 12.3547px 12.3547px 0 0 !important;

}

.my-login-bg {
  background-image: url("../../assets/media/auth/imh-login.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bc-card-header {
  border-bottom: 1px solid white !important;
}

.my-btn {
  padding: 12px 16px;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  font-size: clamp(1.00rem, calc(0.62rem + 0.42vw), 1.63rem);
  font-weight: 700;
  font-family: RobotoSlab, serif;
  border: none;
}

.container, .container-xxl, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  background-color: #F5F8FA !important;
}

span.menu-link {
  height: 64px !important;
  border-radius: 9px !important;
}

.menu-item {
  a {
    height: 64px !important;
    border-radius: 9px !important;
  }
}


#ngSelect .ng-value-label {
  padding-right: 1rem !important;
  padding-left: 0.3rem !important;
}

#ngSelect .form-chek {
  padding-right: 0.5rem !important;
}

#ngSelect .ng-option {
  padding-top: 1rem !important;
}

#ngSelect ng-dropdown-panel {
  background: white !important;
  padding: 1rem;
  border: 1px solid #E4E6EF !important;
  border-radius: 12px;
  width: calc(100% + 2rem) !important;
  left: -1rem !important;


}


.active-filter {
  font-weight: 700 !important;
  color: #0B132E !important;
  padding: 9px 6px;
  min-width: 112px;
  height: 36px;
  background: #E5F5FC;
  border: 1.5px solid #9BA3C9 !important;
  border-radius: 17.5px;
}

.font-dashboard-data {
  color: #0B132E;
  font-size: 24px;
  font-family: RobotoSlab, serif, serif !important;
}

th, td {
  padding: 1vw !important;
}

.bg-round-red-light {
  padding: 6px 10px;
  background: #EDD4C8;
  border-radius: 26px;
}

.table-btn {
  background: rgba(255, 91, 35, 0.12);
  border: 1px solid rgba(255, 91, 35, 0.3);
  border-radius: 10px;
  padding: 2px 5px;
}

.modal-dialog {
  width: max-content !important;
}

.my-modal-padding {
  padding-left: 27px;
  padding-right: 18px;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: $backdrop-color;
  backdrop-filter: blur(2px);
}

.modal {
  background: $backdrop-color;
  backdrop-filter: blur(2px);
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}


//<editor-fold desc="Button Styles">
.btn {

  &.btn-success {
    color: #FFFFFF;
    border-color: $btn-success;
    background-color: $btn-success !important;

    &:hover:not(.btn-active) {
      border-color: $btn-success-hover;
      background-color: $btn-success-hover !important;
    }
  }

}

//</editor-fold>

.aside {
  &.aside-dark {
    .aside-logo {
      background-color: #117175;
    }
  }

  .menu {
    .menu-item {

      .menu-link {

        .menu-icon {
          .svg-icon {
            svg {
              [fill] {
                &:not(.permanent):not(g) {
                  fill: #646e9b;
                }
              }
            }
          }
        }

        .menu-title {
          color: #babbc7;
          font-size: 16px;
          font-family: Helvetica, serif !important;
        }

        &.active {
          background-color: white;
          //background-color: #ECFCFF66;
          color: #384792 !important;
          font-size: 16px;
          font-family: Helvetica, serif !important;
          font-weight: 900;

          .menu-icon {

            .svg-icon {

              svg {
                [fill] {
                  &:not(.permanent):not(g) {
                    fill: #384792;
                    font-weight: 900;
                  }
                }
              }
            }
          }
        }

      }


      &.menu-accordion {

        &.here.show {
          .menu-sub-accordion {
            &.menu-active-bg {
              .menu-link {
                background-color: transparent;

                &.active {
                  background-color: rgba(236, 252, 255, 0.4);

                  .bullet-dot {
                    background-color: white;
                  }

                }

                &:hover {
                  .bullet-dot {
                    background-color: #c2c2c2;
                  }
                }
              }
            }
          }

          .menu-link {
            background-color: rgba(236, 252, 255, 0.4);

            .menu-icon {
              .svg-icon {
                svg {
                  [fill] {
                    &:not(.permanent):not(g) {
                      fill: white;
                    }
                  }
                }
              }
            }

          }
        }
      }

    }
  }


  .aside-menu {
    width: 265px;
    background: #117175;
  }

}


.aside-dark .menu .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #dcdcdc4f;
}

.aside-dark .menu .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: white;
}

.aside-dark .menu .menu-item .menu-section {
  color: #dcdcdc !important;
}

.btn-check:checked + .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: white;
}

.aside.aside-dark .aside-toggle svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #c2c2c2;
}


.form-check.form-check-solid .form-check-input:checked {
  background-color: #00CBE7;
}

.consult-card {
  margin-top: 1rem;
  min-width: 337px;
  width: max-content;
  min-height: 96px;
  background: #FFFFFF;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  .text-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .consult-btn {
    padding: 4px 16px;
    min-width: 81px;
    height: 42px;
    background: var(--color-main-blue);
    border-radius: 8px;
  }
}

.btn-oxygen-flow {
  background: var(--color-main-blue);
  color: white;
  border-radius: 8px;
}

.btn-oxygen-flow:hover {
  background: var(--color-secondary-blue);
  color: black;
}
.label-adjustable-oxygen {
  margin-top: 1rem;
  margin-left: 5.85rem;
}
.label-update_adjustable-oxygen {
    margin-left: 12.85rem;
}


.my-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.my-scroll::-webkit-scrollbar:vertical {
  width: 10px;
}

.my-scroll::-webkit-scrollbar-button:increment, .contenedor::-webkit-scrollbar-button {
  display: none;
}

.my-scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.my-scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 2px solid #f1f2f3;
}

.my-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

//Button styles for boolean components
.btn-check:checked + .btn.btn-secondary, .btn-check:active + .btn.btn-secondary, .btn.btn-secondary:focus:not(.btn-active), .btn.btn-secondary:hover:not(.btn-active), .btn.btn-secondary:active:not(.btn-active), .btn.btn-secondary.active, .btn.btn-secondary.show, .show > .btn.btn-secondary {
  color: #FFFFFF;
  border-color: #B5B5C3;
  background-color: #0095E8 !important;
}

.btn-check:checked + .btn.btn-primary, .btn-check:active + .btn.btn-primary, .btn.btn-primary:focus:not(.btn-active), .btn.btn-primary:hover:not(.btn-active), .btn.btn-primary:active:not(.btn-active), .btn.btn-primary.active, .btn.btn-primary.show, .show > .btn.btn-primary {
  color: #FFFFFF;
  border-color: #0095E8;
  background-color: #0095E8 !important;
}

//text archived in profile patient
.archived-text {
  color: #009DE0 !important;
  font-size: 20px !important;
}
.hide-input{
  display: none;
}
.show-input{
  display: block;
}

//<editor-fold desc="Aside menu">
//.aside {
//  &.aside-dark {
//    .aside-logo {
//      background-color: #117175;
//    }
//  }
//
//  .menu {
//    .menu-item {
//
//      .menu-link {
//
//        .menu-icon {
//          .svg-icon {
//            svg {
//              [fill] {
//                &:not(.permanent):not(g) {
//                  fill: #dcdcdc;
//                }
//              }
//            }
//          }
//        }
//
//        .menu-title {
//          color: #dcdcdc;
//        }
//
//        &.active {
//          background-color: rgba(236, 252, 255, 0.4);
//          //background-color: #ECFCFF66;
//          color: white;
//          font-weight: bolder;
//
//          .menu-icon {
//            .svg-icon {
//              svg {
//                [fill] {
//                  &:not(.permanent):not(g) {
//                    fill: white;
//                  }
//                }
//              }
//            }
//          }
//        }
//
//      }
//
//      &.menu-accordion {
//
//        &.here.show {
//          .menu-sub-accordion {
//            &.menu-active-bg {
//              .menu-link {
//                background-color: transparent;
//
//                &.active {
//                  background-color: rgba(236, 252, 255, 0.4);
//
//                  .bullet-dot {
//                    background-color: white;
//                  }
//
//                }
//
//                &:hover {
//                  .bullet-dot {
//                    background-color: #c2c2c2;
//                  }
//                }
//              }
//            }
//          }
//
//          .menu-link {
//            background-color: rgba(236, 252, 255, 0.4);
//
//            .menu-icon {
//              .svg-icon {
//                svg {
//                  [fill] {
//                    &:not(.permanent):not(g) {
//                      fill: white;
//                    }
//                  }
//                }
//              }
//            }
//
//          }
//        }
//      }
//
//    }
//  }
//
//
//  .aside-menu {
//    width: 265px;
//    background: #117175;
//  }
//
//}
//
//.aside-dark .menu .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
//  transition: color 0.2s ease, background-color 0.2s ease;
//  background-color: #dcdcdc4f;
//}
//
//.aside-dark .menu .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g), .aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon svg [fill]:not(.permanent):not(g) {
//  transition: fill 0.3s ease;
//  fill: white;
//}
//
//.aside-dark .menu .menu-item .menu-section {
//  color: #dcdcdc !important;
//}
//
//.btn-check:checked + .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn-check:active + .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:focus:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:hover:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary:active:not(.btn-active) .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.active .svg-icon svg [fill]:not(.permanent):not(g), .btn.btn-active-color-primary.show .svg-icon svg [fill]:not(.permanent):not(g), .show > .btn.btn-active-color-primary .svg-icon svg [fill]:not(.permanent):not(g) {
//  transition: fill 0.3s ease;
//  fill: white;
//}
//
//.aside.aside-dark .aside-toggle svg [fill]:not(.permanent):not(g) {
//  transition: fill 0.3s ease;
//  fill: #c2c2c2;
//}

//</editor-fold>
