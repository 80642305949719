$backdrop-color: rgba(146, 213, 251, 0.18) !important;

$app-success-color: #3dc763;
$app-danger-color: #ed3d3d;
$app-warning-color: #edb23d;
$app-info-color: #3da4ed;

//
// ng-select
//
@import "@ng-select/ng-select/themes/default.theme.css";

@mixin custom-select-container {
  width: 100%;
  padding: 7px 0;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #181C32;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #E4E6EF;
  appearance: none;
  border-radius: 0.475rem;
  box-shadow: none !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus-within {
    border: 1px solid #B5B5C3 !important;
  }

}

@mixin custom-dropdown-optgroup-marked {
  color: #333 !important;
  background-color: #ebf5ff !important;
}

@mixin valid-invalid-field {
  padding-right: calc(1.5em + 1.5rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.375rem) center;
  background-size: calc(0.75em + 0.75rem) calc(0.75em + 0.75rem);
}

.ng-select {

  .ng-clear-wrapper {
    cursor: pointer;
    position: relative;
    width: 17px;
    -webkit-user-select: none;
    user-select: none;
    transform: translateX(-4%);
    text-align: center;
  }


  .ng-input {
    padding: 4px;
  }

  //.ng-input > input {
  //  box-sizing: content-box;
  //  background: none transparent;
  //  border: 0 none;
  //  box-shadow: none;
  //  outline: none;
  //  padding: 0;
  //  cursor: default;
  //  width: 100%;
  //
  //  &:focus {
  //    & ~ .ng-select-container {
  //      border: 1px solid #0202f7!important;
  //    }
  //  }
  //}


  &.ng-select-multiple {
    .ng-select-container {
      @include custom-select-container;

      .ng-placeholder {
        top: 12px !important;
      }

      .ng-value {
        @include custom-dropdown-optgroup-marked;
        font-size: 14px;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      @include custom-select-container;
      height: 45px;
    }
  }


  &.is-valid {
    .ng-select-container {
      @include valid-invalid-field;
      border-color: #50CD89;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2350CD89' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    }
  }

  &.is-invalid {
    .ng-select-container {
      @include valid-invalid-field;
      border-color: #F1416C;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23F1416C'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23F1416C' stroke='none'/%3e%3c/svg%3e");
    }
  }


}

.ng-dropdown-panel {
  .ng-dropdown-panel-items {
    .ng-option.ng-option-selected,
    .ng-option.ng-option-selected.ng-option-marked {
      @include custom-dropdown-optgroup-marked;
    }
  }
}


//
// sweetAlert2
//
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: $backdrop-color;
  backdrop-filter: blur(2px);
}


//
// ng bootstrap
//
.modal {
  background: $backdrop-color;
  backdrop-filter: blur(2px);
}

//
// angular material
//
.cdk-global-overlay-wrapper, .cdk-overlay-container {
  z-index: 99999 !important;
}


//
// ngx-toastr
//
.ngx-toastr {
  cursor: pointer;
  border-radius: 10px !important;
}

.ngx-toastr.toast-success {
  background-color: $app-success-color !important;
}

.ngx-toastr.toast-error {
  background-color: $app-danger-color !important;
}

.ngx-toastr.toast-warning {
  background-color: $app-warning-color !important;
}

.ngx-toastr.toast-info {
  background-color: $app-info-color !important;
}

.connection-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEp0lEQVR4nO2YW4hVZRTHz5iO18rJvIw2aQM61iEpJMEXyaDmpYv4UJKSaZO9pBmY1oMgat4ftKAoC3oJTKnmwUv0oBaDBVLB6IOT5gXtpqVFpjWpv1jMf+tiz977nDlnOxfcfzhw2N/3rfVf3/ety7dyuQwZMmTI0BMBVAB5YC6wBvgYaAaOAmeBVv3O6luz5qwG5mhtRVeRvwV4FtgK/Er5MBkfAbNNdmcY8AiwBbgQIvIzsA1YCswAJgJjgCqgj35V+jZRc5ZqzS8hWRek4+G0yd8EPAV865RdAfYBC4C6FHSMB14CvpLsAN8ATxqHchU8BBx0gn8HXredLZd8gs67gFXyqQDmVw+WImyk7r+/w7b7A68L+2gOg4CFwGnHw/youlgBjwFntPAfYG0hB5QPTAZeAd4HvgSORUStYxqzOYu0pk8B2bcC64F/xckMe7QQmTfdHd0P3J0wf4B851Pgb0rHeeAT+UL/BH15+Uzgo5uA3uFJNwOfadJlYHncTgE1wDrgj5Dz2z1+A5hn91l3PRy1ajU2T3ObQ859TjfgjoTNXimOhl3GPRisBr7TwF/AEzFChujEWp3Be4EG4PZcibC1MuwLZ1SrdnxIzJpp4oqiabV9bHQ7MiUmc7/gooj5zWZgXKnkE4yqA96TDqTz+ajMb1wd70b7MAL4Xh/2XT2qa6dlxxdgS9yxp2xQjSJUgB3GM+QKxhVxH+EXWkQx7JEjTwJ+1LfjwNQCym+zawlsAHYCLRFRq0WkLAo9bn5TRB47IQ6ngAfEzTgizjXhRbWaHESsi/pv+WRwjKJK4Gmd2iU6jv9ktMmojNFRpVImKF/2O8Nqk0qFoAg0x1sWcz/7Ai87w5Eh5rAr5Iz5iKiV19gK5RNv/EnJrIzx02UuGBjH8UmnaYsmKOk8FzNe73wquKPzgWGJgqNlDVfFEJZXHzN/rrhNKFZBu0wO9AslzB+UEHt11IAI2b1UER91t8HyTL9iuHVE0VDga3eF7AHVt1wDYjZrnbtyVg0PTUu4ZejDEnw6qQq1olK12ttAk9ad1++wvr1ltZJFnwQ5U129d8Q4pGFIvSvYNsXMqVUiCyJdMbioxBoZeWjL7Eh3pM+UYsx0hUrDktCTd7Mbu6RdX6zMW6dTGqj/UzTW5OolW/uuv/vAEjc2PRUjnPCZTvl8JUw7dlRSbPTZtwh51dr14LRN1iTJRrpmpmqEU96giHLFFY+W0EaXIXOMK4NanfyGdNm3V/yiC5FrUgq/FbpOlyV7UTpsCyu2ez4rZmyw+lX2WDrkotYh9bPmJJQ8s0x2riuhsuU1PY4KwQrIV69HLioLwCiXMA0HVHrkXdTKq+VjY7iENyrXHUBbxyUoHu00nklqgcofZruTs7UjO5d1PLF3ROpPezMUseY+9+L8II2AkQpU9H3oTuX+hLn3Ar9p7rZ2nZCuhlqrW11Ndk/EnLHAT5rTWKin1WXQi3G7u/tX6yjgTj2ZDZ9HlejdCtZkA3aLsL23R1vDwr01mjqz9ZpG3zbocrToh74NyvUk0NazDRoFqAmY2DXptlAX8YB+kV3DHgPaGoBFl/cZMmTIcGPgf6FOo75DOaZ9AAAAAElFTkSuQmCC");
  background-color: $app-success-color !important;
}

.connection-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEp0lEQVR4nO2YW4hVZRTHz5iO18rJvIw2aQM61iEpJMEXyaDmpYv4UJKSaZO9pBmY1oMgat4ftKAoC3oJTKnmwUv0oBaDBVLB6IOT5gXtpqVFpjWpv1jMf+tiz977nDlnOxfcfzhw2N/3rfVf3/ety7dyuQwZMmTI0BMBVAB5YC6wBvgYaAaOAmeBVv3O6luz5qwG5mhtRVeRvwV4FtgK/Er5MBkfAbNNdmcY8AiwBbgQIvIzsA1YCswAJgJjgCqgj35V+jZRc5ZqzS8hWRek4+G0yd8EPAV865RdAfYBC4C6FHSMB14CvpLsAN8ATxqHchU8BBx0gn8HXredLZd8gs67gFXyqQDmVw+WImyk7r+/w7b7A68L+2gOg4CFwGnHw/youlgBjwFntPAfYG0hB5QPTAZeAd4HvgSORUStYxqzOYu0pk8B2bcC64F/xckMe7QQmTfdHd0P3J0wf4B851Pgb0rHeeAT+UL/BH15+Uzgo5uA3uFJNwOfadJlYHncTgE1wDrgj5Dz2z1+A5hn91l3PRy1ajU2T3ObQ859TjfgjoTNXimOhl3GPRisBr7TwF/AEzFChujEWp3Be4EG4PZcibC1MuwLZ1SrdnxIzJpp4oqiabV9bHQ7MiUmc7/gooj5zWZgXKnkE4yqA96TDqTz+ajMb1wd70b7MAL4Xh/2XT2qa6dlxxdgS9yxp2xQjSJUgB3GM+QKxhVxH+EXWkQx7JEjTwJ+1LfjwNQCym+zawlsAHYCLRFRq0WkLAo9bn5TRB47IQ6ngAfEzTgizjXhRbWaHESsi/pv+WRwjKJK4Gmd2iU6jv9ktMmojNFRpVImKF/2O8Nqk0qFoAg0x1sWcz/7Ai87w5Eh5rAr5Iz5iKiV19gK5RNv/EnJrIzx02UuGBjH8UmnaYsmKOk8FzNe73wquKPzgWGJgqNlDVfFEJZXHzN/rrhNKFZBu0wO9AslzB+UEHt11IAI2b1UER91t8HyTL9iuHVE0VDga3eF7AHVt1wDYjZrnbtyVg0PTUu4ZejDEnw6qQq1olK12ttAk9ad1++wvr1ltZJFnwQ5U129d8Q4pGFIvSvYNsXMqVUiCyJdMbioxBoZeWjL7Eh3pM+UYsx0hUrDktCTd7Mbu6RdX6zMW6dTGqj/UzTW5OolW/uuv/vAEjc2PRUjnPCZTvl8JUw7dlRSbPTZtwh51dr14LRN1iTJRrpmpmqEU96giHLFFY+W0EaXIXOMK4NanfyGdNm3V/yiC5FrUgq/FbpOlyV7UTpsCyu2ez4rZmyw+lX2WDrkotYh9bPmJJQ8s0x2riuhsuU1PY4KwQrIV69HLioLwCiXMA0HVHrkXdTKq+VjY7iENyrXHUBbxyUoHu00nklqgcofZruTs7UjO5d1PLF3ROpPezMUseY+9+L8II2AkQpU9H3oTuX+hLn3Ar9p7rZ2nZCuhlqrW11Ndk/EnLHAT5rTWKin1WXQi3G7u/tX6yjgTj2ZDZ9HlejdCtZkA3aLsL23R1vDwr01mjqz9ZpG3zbocrToh74NyvUk0NazDRoFqAmY2DXptlAX8YB+kV3DHgPaGoBFl/cZMmTIcGPgf6FOo75DOaZ9AAAAAElFTkSuQmCC");
  background-color: $app-danger-color !important;
}
